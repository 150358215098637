import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Wrapper = styled.div`
  a {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
    font-family: ${({ theme }) => theme.fonts.primary};
    transition: all 0.1s ease;
  }

  ${({ desktop }) =>
    desktop
      ? `
			@media (max-width: 960px) {
					display: none;
			}

			a {
					margin-right: 1.5rem;

					&:last-child {
							margin-right: unset;
					}
			}
		`
      : `
			padding: 5rem 1.5rem;
			display: flex;
			flex-direction: column;

			a {
					margin-bottom: 1rem;

					&:last-child {
							margin-bottom: unset;
					}
			}
	`}
`

const NavbarLinks = ({ desktop }) => {
  return (
    <Wrapper desktop={desktop}>
      <Link to="/letters" activeClassName="active">
        Letters to Laurel
      </Link>
      <Link to="/gallery" activeClassName="active">
        Photo Gallery
      </Link>
      <Link to="/lineup" activeClassName="active">
        Lineup
      </Link>
      <Link to="/contact" activeClassName="active">
        Contact
      </Link>
    </Wrapper>
  )
}

export default NavbarLinks
