import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Logo from "../../../images/logo-white.svg"

const Wrapper = styled.div`
  img {
    position: relative;
    top: -5px;
    width: 250px;
  }

  @media (max-width: 960px) {
    img {
      width: 200px;
      top: -3px;
      left: 10px;
    }
    margin-right: 1rem;
  }
`

const Brand = () => {
  return (
    <Wrapper>
      <Link to="/">
        <img src={Logo} alt="Main Logo" />
      </Link>
    </Wrapper>
  )
}

export default Brand
