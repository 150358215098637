import styled from "styled-components"

const H1 = styled.h1`
  font-size: 3.052rem;
  font-family: ${props => props.theme.fonts.headline};
  font-weight: bold;
  color: ${props => props.color || props.theme.colors.dark};
  line-height: 1.3;
  margin: 1rem 0;
`
const H2 = styled.h2`
  font-size: 2.441rem;
  font-family: ${props => props.theme.fonts.headline};
  font-weight: bold;
  color: ${props => props.color || props.theme.colors.dark};
  line-height: 1.3;
  margin: 1rem 0;
`
const H3 = styled.h3`
  font-size: 1.953rem;
  font-family: ${props => props.theme.fonts.headline};
  font-weight: bold;
  color: ${props => props.color || props.theme.colors.dark};
  line-height: 1.3;
  margin: 1rem 0;
`
const H4 = styled.h4`
  font-size: 1.563rem;
  font-family: ${props => props.theme.fonts.headline};
  font-weight: bold;
  color: ${props => props.color || props.theme.colors.dark};
  line-height: 1.3;
  margin: 1rem 0;
`
const H5 = styled.h5`
  font-size: 1.25rem;
  font-family: ${props => props.theme.fonts.headline};
  font-weight: bold;
  color: ${props => props.color || props.theme.colors.dark};
  line-height: 1.3;
  margin: 1rem 0;
`
const H6 = styled.h6`
  font-size: 1rem;
  font-family: ${props => props.theme.fonts.headline};
  font-weight: bold;
  color: ${props => props.color || props.theme.colors.dark};
  line-height: 1.3;
  margin: 1rem 0;
`

export { H1, H2, H3, H4, H5, H6 }
