const Theme = {
  fonts: {
    headline: `Work Sans, Helvetiva, Arial, sans-serif`,
    body: `Lora, Georgia, serif`,
  },
  colors: {
    primary: {
      main: `#7C1234`,
      shade: `#570D24`,
      tint: `#A35971`,
    },
    dark: `#333333`,
    white: `#ffffff`,
    light: `#f5f5f5`,
    lightAccent: `#F2EDDB`,
  },
}

export default Theme
