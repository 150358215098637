import React from "react"
import { ThemeProvider } from "styled-components"

// Components
import LayoutWrapper from "./LayoutWrapper"
import { Header, Footer } from "../../navigation"
import Theme from "../../../styles/Theme"
import GlobalStyle from "../../../styles/GlobalStyle"

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Header />
      <LayoutWrapper>{children}</LayoutWrapper>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout
