import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,700;1,400&family=Work+Sans:wght@400;700&display=swap');

    body {
        margin: 0;
        padding: 0;
        font-family: ${({ theme }) => theme.fonts.headline};
        font-size: 16px;
    }

    p, span, ul, li {
        line-height: 1.7
    }
`

export default GlobalStyle
