import React from "react"
import styled from "styled-components"

// Components
import NavbarLinks from "./NavbarLinks"
import Brand from "./Brand"

const Wrapper = styled.div`
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    padding: 1.5rem 0.5rem;
  }
`

const Navbar = () => {
  return (
    <Wrapper>
      <Brand />
      <NavbarLinks desktop />
    </Wrapper>
  )
}

export default Navbar
