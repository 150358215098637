import React, { useState } from "react"
import styled from "styled-components"

// Components
import Hamburger from "./Hamburger"
import Navbar from "./Navbar"
import Sidebar from "./Sidebar"

const Wrapper = styled.header`
  background: ${({ theme }) => theme.colors.primary.main};
  max-width: 100vw;
`

const Overlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  display: none;
  transition: 0.4s;

  ${({ sidebar }) =>
    sidebar &&
    `
            display: block;
            z-index: 4
        `}
`

const Header = () => {
  const [sidebar, toggle] = useState(false)

  return (
    <Wrapper>
      <Overlay sidebar={sidebar} onClick={() => toggle(!sidebar)} />
      <Navbar />
      <Hamburger sidebar={sidebar} toggle={toggle} />
      <Sidebar sidebar={sidebar} toggle={toggle} />
    </Wrapper>
  )
}

export default Header
