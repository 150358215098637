import styled from "styled-components"

const GridContainer = styled.div`
  display: grid;
  max-width: ${props => props.maxWidth};
  grid-template-columns: ${props => props.gridTemplate || `repeat(12, 1fr)`};
  margin: ${props => props.margin || `0 auto`};
  padding: ${props => props.padding || `2rem 1rem`};
`

export default GridContainer
