import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

// Components
import { Paragraph } from "../../typography"
import Logo from "../../../images/logo-primary.svg"

const Wrapper = styled.footer`
  background: ${props => props.theme.colors.lightAccent};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 2.5rem;
  margin-top: 5rem;
  align-items: center;

  @media (max-width: 750px) {
    flex-direction: column-reverse;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;

  @media (max-width: 750px) {
    margin-bottom: 2.5rem;
    text-align: left;
  }
`

const StyledLink = styled(Link)`
  font-family: ${props => props.theme.fonts.body};
  color: ${props => props.theme.colors.dark};
  font-size: 1rem;
  margin: 0.5rem 0;
  text-decoration: none;
`

const Footer = () => {
  return (
    <Wrapper>
      <div>
        <img src={Logo} alt="Logo" />
        <Paragraph>
          Copyright &copy; {new Date().getFullYear()} Laurel Tindall, All Rights
          Reserved.
        </Paragraph>
        <Paragraph>
          Made with{" "}
          <span role="img" aria-label="emoji">
            ❤️
          </span>{" "}
          by Jory Tindall at Hype Creative Studios.
        </Paragraph>
      </div>
      <LinkWrapper>
        <StyledLink to="/about">About</StyledLink>
        <StyledLink to="/letters">Letters to Laurel</StyledLink>
        <StyledLink to="/gallery">Photo Gallery</StyledLink>
        <StyledLink to="/lineup">Lineup</StyledLink>
        <StyledLink to="/contact">Contact</StyledLink>
      </LinkWrapper>
    </Wrapper>
  )
}

export default Footer
