import styled from "styled-components"

const Paragraph = styled.p`
  font-family: ${props => props.theme.fonts.body};
  font-size: ${props =>
    props.primary ? `1rem` : props.lead ? `1.25rem` : `1rem`};
  line-height: 1.7;
  color: ${props => props.color || props.theme.colors.dark};
  margin: 1rem 0;
  font-style: ${props => (props.emphasis ? `italic` : `normal`)};

  @media (max-width: 750px) {
    font-size: 1rem;
  }
`

export { Paragraph }
